import Vue from "vue";
import { companyCustomerList } from "@/api/LanjiangAccountInfo";
const common = {
  state: {
    unitList: [],
    unitTotal: 0,
    companyList: [],
    companyTotal: 0,
    companyId: "",
  },

  mutations: {
    SET_COMPANY_ID: (state, companyId) => {
      state.companyId = companyId;
    },
    SET_UNIT_LIST: (state, { list, page_index }) => {
      // state.unitList = list;
      if (page_index === 1) {
        state.unitList = list;
      } else {
        state.unitList = [...state.unitList, ...list];
      }
    },
    SET_UNIT_LIST_TOTAL: (state, total) => {
      state.unitTotal = total;
    },
    SET_COMPANY_LIST: (state, { list, page_index }) => {
      if (page_index === 1) {
        state.companyList = list;
      } else {
        state.companyList = [...state.companyList, ...list];
      }
    },
    SET_COMPANY_LIST_TOTAL: (state, total) => {
      state.companyTotal = total;
    },
  },

  actions: {
    getUnitList: ({ commit }, payload) => {
      const { page_index = 1, page_size = 10, search = "" } = payload;
      return new Promise((resolve, reject) => {
        return Vue.prototype.$api.UserInfo.companyListSearch({
          page_index,
          page_size,
          search,
        })
          .then((res) => {
            const { err_code, err_data } = res;
            if (err_code === Vue.prototype.CODE_OK) {
              commit("SET_UNIT_LIST", { list: err_data.list, page_index });
              commit("SET_UNIT_LIST_TOTAL", err_data.total);
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCompanyList: ({ commit }, payload) => {
      const { page_index = 1, page_size = 10, search = "" } = payload;
      return new Promise((resolve, reject) => {
        return companyCustomerList({
          page_index,
          page_size,
          search,
        })
          .then((res) => {
            const { err_code, err_data } = res;
            if (err_code === Vue.prototype.CODE_OK) {
              commit("SET_COMPANY_LIST", { list: err_data.list, page_index });
              commit("SET_COMPANY_LIST_TOTAL", err_data.total);
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default common;
