import request from "@utils/request";

// 查询所有系统配置
export default {
  list(data) {
    return request({
      url: "/sharingPath/list",
      method: "get",
      params: data,
    });
  },
  delInfo(data) {
    return request({
      url: "/sharingPath/del",
      method: "DELETE",
      data,
    });
  },
  getInfo(data) {
    return request({
      url: "/sharingPath/info",
      method: "GET",
      params: data,
    });
  },
};
