import request from "@utils/request";

const urlParams = {
  operationRecordList: "/operation/record/list", // 操作记录列表
  operationRecordInfo: "/operation/record/info", // 操作记录详情
};

// 查询所有系统配置
export default {
  operationRecordInfo(data) {
    return request({
      url: urlParams.operationRecordInfo,
      method: "get",
			params: data,
    });
  },
  operationRecordList(data) {
    return request({
      url: urlParams.operationRecordList,
      method: "get",
      params: data,
    });
  }
};
