import request from "@utils/request";

const urlParams = {
  companyList: "/company/params/list",
  carList: "/job/detection/list",
  carTypeList: "/product_model/all/list",
  carWarn: "/agv/car/alarm/list",
  carStatusInfo: "/agvCar/status/info",
  trackList: "/agv/car/history/track/list",
  carDetail: "/agvCar/detail",
};

export function companyList(params) {
  return request({
    url: urlParams.companyList,
    method: "get",
    params,
  });
}
export function carList(params) {
  return request({
    url: urlParams.carList,
    method: "get",
    params,
  });
}
export function carTypeList(params) {
  return request({
    url: urlParams.carTypeList,
    method: "get",
    params,
  });
}
export function carWarn(params) {
  return request({
    url: urlParams.carWarn,
    method: "get",
    params,
  });
}
export function carStatusInfo(params) {
  return request({
    url: urlParams.carStatusInfo,
    method: "get",
    params,
  });
}

export function trackList(params) {
  return request({
    url: urlParams.trackList,
    method: "get",
    params,
  });
}

export function carDetail(params) {
  return request({
    url: urlParams.carDetail,
    method: "get",
    params,
  });
}

//无人车操作记录
export const agvAlarmList = (params) => {
  return request({
    url: "/device/opt/agv/list",
    method: "get",
    params,
  });
};
//无人车历史轨迹列表
export const agvHistoryList = (params) => {
  return request({
    url: "/agvCar/work/info/by/day",
    method: "get",
    params,
  });
};
//作业质量按天
export const agvHistoryListInfo = (params) => {
  return request({
    url: "/agvCar/work/list/by/day",
    method: "get",
    params,
  });
};

//无人车历史轨迹详情
export const agvHistoryInfo = (params) => {
  return request({
    url: "agvCar/work/info",
    method: "get",
    params,
  });
};
//无人车打药记录列表
export const agvPotionList = (params) => {
  return request({
    url: "/agvCar/potion/record/list",
    method: "get",
    params,
  });
};

export const workDate = (params) => {
  return request({
    url: "/agvCar/work/date",
    method: "get",
    params,
  });
};


export const todayWorkInfo = (params) => {
  return request({
    url: "/job/detection/today/work/info",
    method: "get",
    params,
  });
};

export const todayWorkInfoSum = (params) => {
  return request({
    url: "/agvCar/work/info/sum",
    method: "get",
    params,
  });
};

//路径信息
export const pathInfo = (params) => {
  return request({
    url: "/path_point/info",
    method: "get",
    params,
  });
};

export const exportExcel = (params) => {
  return request({
    url: "/job/detection/down",
    method: "get",
    params,
    responseType: "blob",
  });
};
export const exportWorkExcel = (params) => {
  return request({
    url: "/agvCar/work/statistic/export",
    method: "get",
    params,
    responseType: "blob",
  });
};

//地图列表
export const mapList = (params) => {
  return request({
    url: "/job/detection/summary/map/list",
    method: "get",
    params,
  });
};
//车辆统计列表
export const summaryList = (params) => {
  return request({
    url: "/job/detection/summary/list",
    method: "get",
    params,
  });
};
//车辆实时数据累计信息
export const agvCarWorkInfoSumAll = (params) => {
  return request({
    url: "/agvCar/work/info/sum/all",
    method: "get",
    params,
  });
};

//车辆统计信息
export const summary = (params) => {
  return request({
    url: "/job/detection/summary",
    method: "get",
    params,
  });
};

//无人车历史上报数据
export const historyAppear = (params) => {
  return request({
    url: "/agv/car/history/track/device/attr",
    method: "get",
    params,
  });
};

//图像
export const imgList = (params) => {
  return request({
    url: "/agvCar/image/list",
    method: "get",
    params,
  });
};

//无人车最后一次上报日期
export const agvLastTime = (params) => {
  return request({
    url: "/agv/car/history/track/last/time",
    method: "get",
    params,
  });
};

//获取无人车历史轨迹详细信息
export const agvTimeList = (params) => {
  return request({
    url: "/agv/car/history/track/list/by/time",
    method: "get",
    params,
  });
};