import request from "@utils/request";

// 查询所有系统配置
export default {
  caseCategoryAdd(data) {
    return request({
      url: "/case/category/add",
      method: "post",
      data,
    });
  },
  caseAdd(data) {
    return request({
      url: "/case/add",
      method: "post",
      data,
    });
  },
  caseCategoryList(data) {
    return request({
      url: "/case/category/list",
      method: "get",
      params: data,
    });
  },
  caseList(data) {
    return request({
      url: "/case/list",
      method: "get",
      params: data,
    });
  },
  caseCategoryInfo(data) {
    return request({
      url: "/case/category/info",
      method: "get",
      params: data,
    });
  },
  caseInfo(data) {
    return request({
      url: "/case/info",
      method: "get",
      params: data,
    });
  },
  caseCategoryEdit(data) {
    return request({
      url: "/case/category/edit",
      method: "put",
      data,
    });
  },
  caseEdit(data) {
    return request({
      url: "/case/edit",
      method: "put",
      data,
    });
  },
  caseCategoryDel(data) {
    return request({
      url: "/case/category/del",
      method: "delete",
      data,
    });
  },
  caseDel(data) {
    return request({
      url: "/case/del",
      method: "delete",
      data,
    });
  },
};
