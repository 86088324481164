const state = {
  /**
   * type: 1,//1创建,2编辑
   * show: false, //弹窗显示隐藏
   * form: {} //表单数据
   * isMapRefresh: new Date()//刷新地图
   */
  isMapRefresh: new Date(), //监听map是否刷新数据
  baseForm: {},
  plotForm: {},
  atBaseId: "", //当前选中的基地id
};

const getters = {};

const mutations = {
  SET_MAP_REFRESH(state, value) {
    state.isMapRefresh = value;
  },
  SET_BASE_FORM(state, value) {
    state.baseForm = value;
  },
  SET_PLOT_FORM(state, value) {
    state.plotForm = value;
  },
  SET_AT_BASE_ID(state, value) {
    state.atBaseId = value;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
