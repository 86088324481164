<template>
  <div class="login-container flex justify-between">
    <div class="login-container-img flex-1">
      <img src="@/assets/images/login/login_logo.png" alt="" />
    </div>
    <div
      class="login-container-form flex-1 flex flex-column justify-center align-center"
    >
      <div class="form-wd">
        <div class="login-title">
          <div class="cn">登录</div>
          <div class="en m-t-10">LanJiang Manage</div>
        </div>
        <div class="tab-list m-t-60 m-b-40 flex text-center">
          <div
            class="pointer"
            :class="{ active: loginIndex === index }"
            v-for="(item, index) in loginType"
            :key="index"
            @click="changeLoginType(index)"
          >
            {{ item }}
          </div>
        </div>
        <!-- 账号密码登录 -->
        <a-form-model
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          v-show="loginIndex === 0"
        >
          <a-form-model-item ref="user_name" prop="user_name">
            <span class="svg-container"
              ><svg-icon icon-class="user_new"
            /></span>
            <a-input
              v-model="loginForm.user_name"
              placeholder="用户名"
              name="username"
              type="text"
              tabindex="1"
              auto-complete="on"
              @blur="
                () => {
                  $refs.user_name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="password" prop="password">
            <span class="svg-container"
              ><svg-icon icon-class="password_new"
            /></span>
            <a-input
              :key="passwordType"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="密码"
              name="password"
              tabindex="2"
              auto-complete="on"
              @blur="
                () => {
                  $refs.password.onFieldBlur();
                }
              "
              @keyup.enter.native="handleLogin"
            />
            <span class="show-pwd" @click="showPwd"
              ><svg-icon
                :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
            /></span>
          </a-form-model-item>
          <div class="flex align-center m-b-40">
            <a-checkbox v-model="checked">记住密码</a-checkbox>
          </div>
          <a-button
            :loading="loading"
            type="primary"
            style="width: 100%"
            @click.native.prevent="handleLogin"
            >登录</a-button
          >
        </a-form-model>
        <!-- 钉钉登录 -->
        <div
          class="ding flex flex-column align-center p-t-30"
          v-show="loginIndex === 1"
        >
          <img src="@assets/images/icon/dingding_icon.png" />
          <a-button
            type="primary"
            class="m-t-50"
            style="width: 100%"
            @click.native.prevent="openDing"
            >立即登录</a-button
          >
        </div>
      </div>
      <div class="copy flex justify-center">
        Copyright @ 2021-2022 Lan Jiang. All Rights Reserved
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import storage from "@/utils/storage";
export default {
  name: "Login",
  data() {
    return {
      loginType: ["账户登录", "钉钉登录"],
      loginIndex: 0,
      loginForm: {
        user_name: "123123",
        password: "123123",
      },
      loginRules: {
        user_name: [
          { required: true, trigger: "blur", message: "请输入您的账号" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
        ],
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
      checked: true,
      imgBase: "",
    };
  },
  computed: {
    ...mapState({
      systemName: (state) => state.user.systemName,
    }),
    getCodeImg() {
      return `data:image/jpg;base64,${this.imgBase}`;
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    const { user_name, password, checked } =
      storage.getItem("rememberUserAdmin") || {};
    this.checked = checked;
    this.loginForm.user_name = user_name;
    this.loginForm.password = password;
  },
  methods: {
    // 切换登录方式
    changeLoginType(index) {
      this.loginIndex = index;
      this.$refs["loginForm"].clearValidate();
    },
    openDing() {
      this.$router.push("/login-redirect");
      // window.location.href = "/#/login-redirect";
    },
    _getVercode() {
      this.$api.login.getVercode().then((res) => {
        const { err_code, err_data } = res;
        if (err_code === 200) {
          const { ver_img, ver_id } = err_data;
          this.imgBase = ver_img;
          this.loginForm.ver_id = ver_id;
        }
      });
    },
    // 获取系统信息
    getSystemInfo() {
      this.$store.dispatch("systemsetting");
    },
    // 明文显示
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      /*  this.$nextTick(() => {
        this.$refs.password.focus();
      }); */
    },
    // 登录功能
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        // console.log("haha", valid);
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("login", this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || "/BigScreen" });
              this.loading = false;
              this.setRemember();
            })
            .catch(() => {
              // console.log(err);
              /*     this.loginForm.ver_id = "";
              this.loginForm.ver_code = ""; */
              // this._getVercode();
              this.loading = false;
            });
        }
      });
    },
    setRemember() {
      if (this.checked) {
        storage.setItem("rememberUserAdmin", {
          ...this.loginForm,
          checked: this.checked,
        });
      } else {
        storage.setItem("rememberUserAdmin", { checked: this.checked });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@bg: #2d3a4b;
@dark_gray: #889aa4;
@light_gray: #eee;
@bg2: #283443;
@light_gray: #afafaf;
@cursor: #fff;

.login-container {
  min-height: 100%;
  width: 100%;
  background: #fff;
  overflow: hidden;

  .ant-btn {
    height: 40px;
    background: #4378db;
    border-radius: 3px;
    color: #fff;
  }
  .ant-input {
    display: inline-block;
    height: 30px;
    width: 82%;
  }
  input.ant-input {
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    padding: 5px 10px;
    color: @light_gray;
    height: 30px;
    caret-color: @cursor;
    outline: none;
    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px @bg2 inset !important;
      -webkit-text-fill-color: @cursor !important;
    }
    &:focus {
      outline: none;
    }
  }
  .ant-form-item {
    margin-bottom: 24px;
  }
  ::v-deep .ant-form-item-children {
    display: block;
    border-radius: 4px;
    color: #454545;
    border: 1px solid #dcdcdc;
  }
}
.login-container-img {
  background: url("../../assets/images/login/login_back.png") no-repeat center
    center;
  background-size: cover;
  img {
    width: 138px;
    height: 30px;
    margin: 8.33% 0 0 6.25%;
  }
}
.login-container-form {
  position: relative;
  .form-wd {
    width: 400px;
  }

  .login-title {
    font-size: 32px;
    color: #000;
    font-family: "PingFang SC-Semibold";
  }
  .tab-list {
    font-size: 16px;
    > div {
      width: 150px;
      padding-bottom: 15px;
      border-bottom: 2px solid #fff;
    }
    .active {
      color: #3592f1;
      border-bottom: 2px solid #3592f1;
    }
  }

  .ant-form {
    height: 254px;
  }

  .copy {
    position: absolute;
    bottom: 6%;
    left: 0;
    width: 100%;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }

  .svg-container {
    color: #b2b2b2;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
    text-align: center;
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: #b2b2b2;
    cursor: pointer;
    user-select: none;
  }
  .ding {
    height: 254px;
    img {
      width: 88px;
      height: 88px;
    }
  }
}
</style>
