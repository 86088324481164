<template>
  <div>
    <a-menu
      :inlineIndent="inlineIndent"
      :selectedKeys="[$route.path]"
      :openKeys="openKeys"
      :mode="mode"
      :collapsed="collapsed"
      @openChange="onOpenChange"
      @click="menuClick"
    >
      <!-- sidebarMenu -->
      <template v-for="item in sidebarSecondMenuFormat">
        <template v-if="!item.meta.hideMenu">
          <a-menu-item v-if="!item.children" :key="item.menu_url">
            <i :class="['iconfont m-r-10', item.icon]" />
            <span class="menu_name">{{ item.title }}</span>
          </a-menu-item>
          <template v-else>
            <sub-menu :key="item.menu_url" :menu-info="item" />
          </template>
        </template>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SubMenu from "@components/DynamicSider";

export default {
  components: {
    SubMenu,
  },
  data() {
    return {
      inlineIndent: 12, // 菜单缩进
      collapsed: false, // 默认不折叠
      openKeys: [], //默认展开的节点
      defaultOpenKeys: [],
      sidebarSecondMenuFormat: [], // 选中的子菜单项
    };
  },
  computed: {
    mode: {
      get() {
        return this.sidebar.opened ? "inline" : "vertical";
      },
    },
    ...mapState({
      sidebarMenu: (state) => state.permissions.sidebarMenu,
      sidebarSecondMenu: (state) => state.permissions.sidebarSecondMenu,
      menuOpenKeys: (state) => state.permissions.menuOpenKeys,
      sidebar: (state) => state.app.sidebar,
    }),
  },
  watch: {
    mode(nv, ov) {
      if (nv !== ov) {
        setTimeout(() => {
          this.changePosition();
        }, 0);
      }
    },
    sidebarSecondMenu: {
      handler(nv, ov) {
        if (nv.menu_url !== ov.menu_url) {
          this.resetSidebarSecondMenuFormat();
        }
      },
      deep: true,
    },
  },
  async created() {
    this.resetSidebarSecondMenuFormat();
  },
  methods: {
    resetSidebarSecondMenuFormat() {
      this.sidebarSecondMenuFormat =
        this.sidebarSecondMenu.children &&
        this.sidebarSecondMenu.children.length > 0
          ? this.sidebarSecondMenu.children
          : [];
      this.setOpenKeys();
    },
    //设置内层菜单位置
    changePosition() {
      const dom = document.querySelector(".ant-menu-submenu-popup");
      if (dom) {
        dom.style.left = this.sidebar.opened ? "200px" : "56px";
      }
    },
    //  控制只打开一个
    onOpenChange(openKeys) {
      if (openKeys.length !== 0) {
        this.openKeys = [openKeys[openKeys.length - 1]];
      } else {
        this.openKeys = [""];
      }
    },
    // 点击菜单，路由跳转,注意的是当点击MenuItem才会触发此函数
    // eslint-disable-next-line no-unused-vars
    menuClick({ item, key, keyPath }) {
      this.$router.push({
        path: key,
      });
    },
    setOpenKeys() {
      this.openKeys = this.menuOpenKeys;
    },
  },
};
</script>
