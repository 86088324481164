const state = {
  // 弹窗信息
  drawerShow: false,
  drawerData: {},
  // 城市信息
  userCityInfo: {},
  // 基地列表
  baseAllList: [],
  // 当前基地信息
  currentBaseInfo: {},
};

const getters = {};

const mutations = {
  SET_DRAWER_SHOW(state, value) {
    state.drawerShow = value;
  },
  SET_DRAWER_DATA(state, value) {
    state.drawerData = value;
  },
  SET_USER_CITY_INFO(state, value) {
    state.userCityInfo = value;
  },
  SET_BASE_ALL_LIST(state, value) {
    state.baseAllList = value;
  },
  SET_CURRENT_BASE_INFO(state, value) {
    state.currentBaseInfo = value;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
