import router from "./index";
import store from "@/store/index";
import getPageTitle from "@/utils/get-page-title";
const whitelist = ["/login", "/login-redirect", "/CarScreen"];
import dynamicRouter from "@/router/dynamic-router";

router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta.title);
  if (!store.state.user.token) {
    // 未登录  页面是否需要登录
    if (to.matched.length > 0 && whitelist.includes(to.path)) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    // 用户已经登录  路由的访问权限
    if (!store.state.permissions.permissionList) {
      store.dispatch("permissions/FETCH_PERMISSION").then(() => {
        next({
          ...to,
        });
      });
    } else {
      // store存在权限
      if (to.path !== "/Login") {
        getSecondMenu(dynamicRouter, to.path);
        next();
      } else {
        next(from.fullPath);
      }
    }
  }
});

// 二级菜单及默认展开菜单处理
function getSecondMenu(data, path) {
  let column = "";
  getColumn(data);
  function getColumn(data) {
    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      if (item.menu_url === path) {
        column = item.column;
        break;
      }
      if (item.children && item.children.length > 0) {
        getColumn(item.children);
      }
    }
  }
  let atSelectObj = {};
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.column === column && item.children && item.children.length > 0) {
      atSelectObj = item;
      break;
    }
  }
  store.commit("permissions/SET_SECOND_MENU", atSelectObj);

  function getOpenKeys(list, keys = []) {
    for (let item of list) {
      if (item.menu_url === path) {
        return [...keys];
      } else if (item.children && item.children.length) {
        let tempResult = getOpenKeys(item.children, [...keys, item.menu_url]);
        if (tempResult !== false) {
          return tempResult;
        }
      }
    }
    return false;
  }
  if (atSelectObj.children && atSelectObj.children.length > 0) {
    store.commit(
      "permissions/SET_MENU_OPEN_KEYS",
      getOpenKeys(atSelectObj.children)
    );
  }
}
