import request from "@utils/request";

export function companyCustomerList(data) {
  return request({
    url: "/company/list/customer",
    method: "get",
    params: data,
  });
}
export function companyUsable(data) {
  return request({
    url: "/companyUser/usable",
    method: "put",
    data,
  });
}
