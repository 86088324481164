import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@views/Login";
import Layout from "@views/Layout";
import NotFound from "@views/404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      hideMenu: true,
      title: "登录",
    },
  },
  {
    path: "/login-redirect",
    name: "LoginRedirect",
    component: () => import("@/views/Login/loginRedirect.vue"),
    meta: {
      hideMenu: true,
      title: "登录跳转",
    },
  },
  {
    path: "/bigScreen",
    name: "BigScreen",
    component: () => import("@/views/BigScreen"),
    meta: {
      hideMenu: true,
      title: "大屏",
    },
  },
  {
    path: "/CarScreen",
    name: "carScreen",
    component: () => import("@/views/CarScreen"),
    meta: {
      hideMenu: true,
      title: "车辆大屏",
    },
  },
  {
    path: "/CarMonitorScreen",
    name: "carMonitorScreen",
    component: () => import("@/views/CarMonitorScreen"),
    meta: {
      hideMenu: false,
      title: "车辆监测大屏",
    },
  },
  {
    path: "/CarMonitorScreen/ScreenDetail",
    name: "ScreenDetail",
    component: () => import("@/views/CarMonitorScreen/ScreenDetail"),
    meta: {
      hideMenu: false,
      title: "车辆监测大屏",
    },
  },
];
export const dynamicRoutes = [
  {
    path: "/",
    menu_url: "/",
    component: Layout,
    redirect: "/WebsiteMa",
    meta: {
      hideMenu: false,
    },
    children: [],
  },
  {
    path: "*",
    component: NotFound,
    meta: {
      hideMenu: true,
    },
  },
];
const router = new VueRouter({
  // routes: routes.concat(dynamicRoutes),
  routes,
});

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const cssPattern = /Loading CSS chunk (\d)+ failed/g;
  const isChunkLoadFailed =
    error.message.match(pattern) || error.message.match(cssPattern);
  if (isChunkLoadFailed) {
    // 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
    location.reload();
    // const targetPath = $router.history.pending.fullPath;
    // $router.replace(targetPath);
  }
});

export default router;
