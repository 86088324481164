import request from "@utils/request";

// 车辆绑定作业
export default {
  //单位列表
  companySearchList(data) {
    return request({
      url: "/company/searchList",
      method: "get",
      params: data,
    });
  },
  // 字典查询车辆类型
  carType(query) {
    return request({
      url: "/dictionary/get/30",
      method: "get",
      params: query,
    });
  },
  // 查询车辆类型
  productNameList(query) {
    return request({
      url: "/product_model/list",
      method: "get",
      params: query,
    });
  },
  // 无人车绑定列表
  AgvCarBindList(data) {
    return request({
      url: "/agv_car_work_binding/list/agv",
      method: "get",
      params: data,
    });
  },
  //  无人车绑定保姆车
  AgvCarBindNanny(data) {
    return request({
      url: "/agv_car_work_binding/list/agv/bind_nanny",
      method: "get",
      params: data,
    });
  },
  // 保姆车绑定列表
  nannyCarBindList(data) {
    return request({
      url: "/agv_car_work_binding/list/nanny",
      method: "get",
      params: data,
    });
  },
  // 保姆车绑定无人车列表
  nannyCarBindAgv(data) {
    return request({
      url: "/agv_car_work_binding/list/nanny/bind_agv",
      method: "get",
      params: data,
    });
  },
  // 保姆车未绑定无人车列表
  nannyCarUnbindAgv(data) {
    return request({
      url: "/agv_car_work_binding/list/nanny/bind_agv/unbind",
      method: "get",
      params: data,
    });
  },

  // 全部作业绑定列表
  CarBindList(data) {
    return request({
      url: "/agv_car_work_binding/list",
      method: "get",
      params: data,
    });
  },
  // 解绑保姆车
  UnbindNanny(data) {
    return request({
      url: "/agv_car_work_binding/unbind/nanny",
      method: "put",
      data,
    });
  },
  // 解绑无人车
  UnbindAgvCar(data) {
    return request({
      url: "/agv_car_work_binding/unbind",
      method: "put",
      data,
    });
  },
  // 绑定车辆
  BindCar(data) {
    return request({
      url: "/agv_car_work_binding/bind",
      method: "put",
      data,
    });
  },
};
