import Vue from "vue";
import { hasPermission } from "@/utils/tools";
Vue.directive("color", {
  bind(el, binding) {
    if (!hasPermission(binding.value, false)) {
      //   el.style.color = "#999";
      el.style.setProperty("color", "#999", "important");
    } else {
      //   el.style.color = "";
      el.style.setProperty("color", "", "");
    }
  },
});
Vue.directive("btn", {
  bind(el, binding) {
    if (!hasPermission(binding.value, false)) {
      el.style.backgroundColor = "#999";
    } else {
      el.style.color = "";
    }
  },
});
